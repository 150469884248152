'use client';

import { useState, useEffect, ReactElement } from 'react';

export function NoSSR({ children }: { children: ReactElement | null }) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient ? children : null;
}
