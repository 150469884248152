'use client';

import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import ReplayIcon from '@mui/icons-material/Replay';

import { colors, styled } from 'core/styles';
import { logger } from 'core/logger';
import { NoSSR } from 'components';

type ErrorProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

// eslint-disable-next-line import/no-default-export
export default function Error({ error, reset }: ErrorProps) {
  useEffect(() => {
    console.error(error);
    logger.captureError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorContainer>
      <span>Service is unavailable. Try to reload later </span>
      <NoSSR>
        <Reload reset={reset} />
      </NoSSR>
    </ErrorContainer>
  );
}

const ErrorContainer = styled('div')(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: theme.typography.secondaryFontFamily,
  fontSize: 18,
}));

function Reload({ reset }: { reset: () => void }) {
  const { totalSeconds } = useTimer({
    expiryTimestamp: new Date(Date.now() + 1500),
  });

  return (
    <ReloadButton type="button" isVisible={totalSeconds === 0} onClick={reset}>
      <ReplayIcon />
    </ReloadButton>
  );
}

const ReloadButton = styled('button', {
  shouldForwardProp: prop => prop !== 'isVisible',
})<{
  isVisible: boolean;
}>(({ isVisible }) => ({
  display: 'flex',
  visibility: isVisible ? 'visible' : 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  width: 24,
  height: 24,
  background: colors.smokyWhite,
  padding: 0,
  marginLeft: 12,
  border: 'none',
  borderRadius: 4,
  color: colors.white,
  cursor: 'pointer',
  transition: 'background 200ms',

  '&:hover': {
    background: colors.silver,
  },
}));
